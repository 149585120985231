
export interface menu {
  header?: string;
  title?: string;
  icon?: string;
  to?: string;
  divider?: boolean;
  getURL?: boolean;
  chip?: string;
  chipColor?: string;
  chipVariant?: string;
  chipIcon?: string;
  children?: menu[];
  disabled?: boolean;
  type?: string;
  subCaption?: string;
}

const sidebarItem: menu[] = [
  {
    title: 'Dashboard',
    icon: 'mdi-view-dashboard',
    to: '/dashboard/overall',
  },
  {
    title: 'Alerts',
    icon: 'mdi-alert',
    to: '/alerts',
  },

  {
    title: 'Device Analytics',
    icon: "mdi-chart-line",
    to: '/deviceAnalytics',
  },
  {
    title: 'Device Info',
    icon: 'mdi-monitor',
    to: '/deviceInfo',
  },
  {
    title: 'Settings',
    icon: 'mdi-cog',
    to: '/settings',

  },
];

export default sidebarItem;
