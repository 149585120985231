<template>
  <v-app :class="[customizer.mini_sidebar ? 'mini-sidebar' : '',
  customizer.setHorizontalLayout ? 'horizontalLayout' : 'verticalLayout'
  ]">
    <template v-if="!customizer.setHorizontalLayout">
      <SmcVerticalSidebar v-if="tenant?.solution === 'SMC'" />
      <FmpSidenavbar v-else-if="tenant?.solution === 'FMP'" />
      <VerticalSidebar v-else />
    </template>
    <v-main>
      <VerticalHeader />
      <slot />
    </v-main>
  </v-app>
</template>
<script setup lang="ts">
import { useCustomizerStore } from '@/stores/customizer'
import { useTenant } from '~/composables/useTenant';
const customizer = useCustomizerStore();
const tenant = await useTenant();
</script>
<style scoped>
@media (min-width: 601px) and (max-width: 780px) {
  .v-container {
    max-width: 780px;
    margin-right: -30px;
  }
}

@media (min-width: 900px) and (max-width: 1100px) {
  .v-container {
    max-width: 1100px;
    margin-right: 0px;
    padding-left: 20px;
  }
}

@media (min-width: 1280px) and (max-width: 1320px) {
  .v-container {
    max-width: 1280px;
    margin-right: -20px;
    padding-left: 50px
  }
}

@media (min-width: 1321px) and (max-width: 1480px) {
  .v-container {
    max-width: 1480px;
    margin-right: -20px;
    padding-left: 50px;
  }
}
</style>
